import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const DrZenLandingPage = () => (
  <PhysicianLandingPage
    physician="Dr. Zen"
    institution="NMD Wellness Of Scottsdale"
    referralCode="DRZEN"
    physicianURL="https://www.nmdwellnessofscottsdale.com/home"
  />
)

export default DrZenLandingPage
